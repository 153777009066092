import React, { useContext, useEffect, useState } from 'react';
import Logo from "../../img/1.png";
import menuImg from "../../img/menu.png";
import { Link, NavLink } from 'react-router-dom';
import authContext from '../../context/authentication/authContext';

const Header = () => {

    const { usuario, logOut, changeEnfoque } = useContext(authContext);

    useEffect(() => {
        const onScroll = () => {
            const wraper = document.getElementById('wraper');
            const logoContainer = document.getElementById('logo_container');
            if (window.pageYOffset <= logoContainer.offsetHeight) {
                wraper.style.top = '-' + window.pageYOffset + 'px';
            } else {
                wraper.style.top = '-' + logoContainer.offsetHeight + 'px';
            }

        };
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const [menu, setMenu] = useState(true);
    useEffect(() => {
        const container = document.getElementById('container-fluid');
        if (menu) {
            container.style.display = "none";
        } else {
            container.style.display = "flex";
        }
    }, [menu])

    return (
        <header className='wraper' id='wraper'>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className='container flexDirection' id='logo_container'>
                    <div className='flex' style={{ alignItems: "center" }}>
                        <Link to="/">
                            <img src={Logo} alt="Logo" width="150" height="150" className="d-inline-block align-text-top" />
                        </Link>
                        <p className='logo_text'>Fundación para el desarrollo<br />solidario y académico</p>
                    </div>
                    <div className='flex'>
                        <div><p>¿Tiene alguna inquietud? <Link style={{ textDecoration: 'none' }} to='/contactenos'>Contáctenos</Link></p></div>
                    </div>
                    <div className='flex redes-header'>
                        <a href='https://www.facebook.com/people/Fundaci%C3%B3n-Solidare/100095514971714/?mibextid=ZbWKwL' target='_blank'><div className='facebook-icon redes-header'></div></a>
                        <a href='https://www.instagram.com/fundacionsolidare?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D' target='_blank'><div className='instagram-icon redes-header'></div></a>
                    </div>
                </div>
            </div>
            <nav className="navbar desktop">
                <div className="container-fluid">
                    <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/">
                        Inicio
                    </NavLink>
                    <div className='nav_dropdown'>
                        <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/quienessomos">
                            ¿Quiénes somos?
                        </NavLink><span className="material-symbols-outlined">
                            arrow_drop_down
                        </span>
                        <div className='hidde'>
                            <Link className="navbar-nav" to="/regimen">
                                Documentos legales
                            </Link>
                            <hr />
                            <Link className="navbar-nav" to="/quienessomos?seccion=seccion-especifica" onClick={changeEnfoque}>
                                Equipo de trabajo
                            </Link>
                        </div>
                    </div>
                    <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/quehacemos">
                        ¿Qué hacemos?
                    </NavLink>
                    <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/experiencias">
                        Experiencias
                    </NavLink>

                    {/* <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/contactenos">
                        Contáctenos
                    </NavLink> */}
                    {/* <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/donacion">
                        Donaciones
                    </NavLink> */}
                    <a className="navbar-nav" href='https://cursos.solidare.com.co/' target='_blank'>Aula virtual</a>
                    <a  className="navbar-nav  donacion-btn" href="https://portalpagos.davivienda.com/#/comercio/10027/SOLIDARE" target='_blank'>
                        Done aquí
                    </a>
                    {usuario ?
                        <span className="material-symbols-outlined" style={{ width: "200px", display: "flex", justifyContent: "right", cursor: "pointer" }} onClick={logOut}>
                            logout
                        </span> : null
                    }
                </div>
            </nav>
            <nav className="navbar mobile">
                <button className="navbar-button navbar" type="button" >
                    <img src={menuImg} className="menu_button" onClick={() => setMenu(!menu)} />
                </button>
                <div className="container-fluid" id="container-fluid">
                    <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/">
                        Inicio
                    </NavLink>
                    {/* <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/quienessomos">
                        ¿Quiénes Somos?
                    </NavLink> */}
                    <div className='nav_dropdown'>
                        <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/quienessomos">
                            ¿Quiénes somos?
                        </NavLink><span className="material-symbols-outlined">
                            arrow_drop_down
                        </span>
                        <div className='hidde'>
                            <Link className="navbar-nav" to="/regimen">
                                Documentos legales
                            </Link>
                            <hr />
                            <Link className="navbar-nav" to="/quienessomos?seccion=seccion-especifica" onClick={changeEnfoque}>
                                Equipo de trabajo
                            </Link>
                        </div>
                    </div>
                    <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/quehacemos">
                        ¿Qué hacemos?
                    </NavLink>
                    <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/experiencias">
                        Experiencias
                    </NavLink>
                    {/* <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/contactenos">
                        Contáctenos
                    </NavLink> */}
                    {/* <NavLink style={({ isActive }) => isActive ? { color: '#d7165b' } : null} className="navbar-nav" to="/donacion">
                        Donaciones
                    </NavLink> */}
                    <a className="navbar-nav" href='https://cursos.solidare.com.co/' target='_blank'>Aula virtual</a>
                    {usuario ?
                        <span className="material-symbols-outlined" style={{ width: "200px", display: "flex", justifyContent: "center", margin: "10px 30px", cursor: "pointer" }} onClick={logOut}>
                            logout
                        </span> : null}
                </div>
            </nav>
        </header>
    );
}

export default Header;