import {
  GET_INFO_QUIENES_SOMOS,
  EDITAR_QUIENES_SOMOS
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case GET_INFO_QUIENES_SOMOS:
      return {
        ...state,
        section1: action.payload.section1,
        banner: action.payload.banner,
        section2: action.payload.section2,
        section3: action.payload.section3
      };
    case EDITAR_QUIENES_SOMOS:
      return {
        ...state,
        section1: JSON.parse(action.payload.section1),
        banner: JSON.parse(action.payload.banner),
        section2: JSON.parse(action.payload.section2),
        section3: JSON.parse(action.payload.section3),
      };
    default:
      return state;
  }
};
