import React from 'react';

const Section = () => {
    return (
        <div className='section_donacion'>
            <div className='section_inner'>
                <div className="container container-contactanos">
                    <form id="formulario-contactanos" >
                        <div className='formulario_items'>
                            <select className="programa" name="programa" style={{ width: "100%" }}>
                                <option value="">Seleccione el programa a donar*</option>
                                <option value={1}>Programa 1</option>
                                <option value={2}>Programa 2</option>
                                <option value={3}>Programa 3</option>
                                <option value={4}>Programa 4</option>
                                <option value={5}>Programa 5</option>
                            </select>
                        </div>
                        <div className='formulario_items'>
                            <input className='no_ident' style={{ width: "100%" }} name="no_ident" type="number" placeholder="Valor de la donación*" />
                        </div>
                        <div className='formulario_items'>
                            <select className="identificacion" name="identificacion">
                                <option value="">Tipo de identificación*</option>
                                <option value="cedula">Cédula</option>
                                <option value="tarjeta_ext">Tarjeta de extranjería</option>
                                <option value="cedula_ext">Cédula de extranjería</option>
                                <option value="NIT">NIT</option>
                                <option value="pasaporte">Pasaporte</option>
                                <option value="documento_ext">Documento de identificación extranjero</option>
                                <option value="tarjeta_identidad">Tarjeta de identidad</option>
                            </select>
                            <input className='no_ident' name="no_ident" type="number" placeholder="No. de identificación*" />
                            <input name="nombres" type="text" placeholder="Nombres*" />
                        </div>
                        <div className='formulario_items'>
                            <input name="apellidos" type="text" placeholder="Apellidos*" />
                            <input name="email" type="text" placeholder="Correo electrónico" />
                            <input name="cell" type="text" placeholder="Número de teléfono*" />
                        </div>
                        <div className='formulario_items'>
                            <input name="city" type="text" placeholder="Dirección*" style={{ width: "100%" }} />
                        </div>
                        <button>Continuar con PSE<i className="fa-solid fa-play fa-2xs"></i></button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Section;