import React, { useContext } from 'react';
import quienesSomosContext from '../../context/quienesSomos/quienesSomosContext';
import urlImages from '../../config/urlImages';

const Banner = () => {

    const { banner } = useContext(quienesSomosContext);

    return (
        <section className='first_section' id="banner_quienes_somos" style={{backgroundImage: `url("${urlImages}uploads/quienes_somos/${banner?.img}")`}}> 
            <div>
                <h1>{banner?.title}</h1>
                <p>
                    {banner?.body}
                </p>
            </div>
        </section>
    );
}

export default Banner;