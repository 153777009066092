import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import experienciasContext from '../../context/experiencias/experienciasContext';
import urlImages from '../../config/urlImages';

const Slider = () => {

    const { slider } = useContext(experienciasContext);

    const [info, setInfo] = useState({
        proyecto: 0,
        width: 0,
        maxWidth: 0,
        maxWidthSlider: 0,
        translate: 0,
        move: 0
    })

    const { proyecto, width, maxWidth, maxWidthSlider, translate, move } = info;

    // useEffect(() => {
    //     window.addEventListener("resize", () => {
    //         setInfo({
    //             ...info,
    //             translate: 0
    //         });
    //         const slider = document.getElementById('slider_inner');
    //         slider.style.transform = 'translateX(0px)';
    //     });
    // }, [])

    useEffect(() => {
        const item = document.querySelector('#item');
        const style = item.currentStyle || window.getComputedStyle(item);
        // console.log(parseInt(style.width));
        setInfo(
            {
                ...info,
                maxWidth: document.getElementById('slider_inner').offsetWidth,
                maxWidthSlider: document.getElementById('slider').offsetWidth,
                width: parseInt(style.marginLeft) * 2 + parseInt(style.width)
            }
        );
        if (parseInt(document.getElementById('slider_inner').offsetWidth) > parseInt(document.getElementById('slider').offsetWidth)) {
            document.getElementById('slider').style.justifyContent = 'flex-start';
            setInfo(
                {
                    ...info,
                    maxWidth: document.getElementById('slider_inner').offsetWidth + 40,
                    maxWidthSlider: document.getElementById('slider').offsetWidth + 40,
                    width: parseInt(style.marginLeft) * 2 + parseInt(style.width),
                    move: 1
                }
            );
        } else {
            document.getElementById('slider').style.justifyContent = 'center';
            setInfo(
                {
                    ...info,
                    maxWidth: document.getElementById('slider_inner').offsetWidth,
                    maxWidthSlider: document.getElementById('slider').offsetWidth,
                    width: parseInt(style.marginLeft) * 2 + parseInt(style.width),
                    move: 0
                }
            );
        }
        document.getElementById('slider_inner').style.transform = 'translateX(0px)';
        // console.log(document.getElementById('slider_inner').offsetWidth, 'slider_inner');
        // console.log(document.getElementById('slider').offsetWidth, 'slider');
    }, [proyecto, slider])

    const prev = () => {
        if (!move) {
            return;
        }
        const slider = document.getElementById('slider_inner');

        if (translate + width < 0) {//verificamos que el tamaño de el desplazamiento sea menor a el contenedor, para evitar que se siga desplazando y deje espacios vacios
            setInfo({
                ...info, translate: translate + width
            });
            slider.style.transform = 'translateX(-' + parseInt(translate + width) + 'px)';//se usa la propiedad transform: translate para el movimiento
        } else {
            setInfo({
                ...info, translate: 0
            });
            slider.style.transform = 'translateX(0px)';//se usa la propiedad transform: translate para el movimiento
        }
    }

    const next = () => {
        if (!move) {
            return;
        }
        const slider_inner = document.getElementById('slider_inner');
        const slider = document.getElementById('slider').offsetWidth;
        // console.log(width)
        if (translate - width > (slider - maxWidth)) {
            setInfo({
                ...info, translate: translate - width
            });
            slider_inner.style.transform = 'translateX(-' + parseInt(translate + width) + 'px)';
        } else {
            setInfo({
                ...info, translate: slider - maxWidth
            });
            slider_inner.style.transform = 'translateX(-' + parseInt(slider - maxWidth) + 'px)';
        }
    }

    return (
        <div className='container_slider'>
            <h1 style={{ margin: "20px", marginTop: "40px", fontWeight: "bold" }}>Nuestras Actividades</h1>
            <div className='proyectos'>
                {slider?.map((item, indx) =>
                    <h2 className={proyecto === indx ? 'proyecto_activo' : ''} key={indx} onClick={() => { setInfo({ ...info, proyecto: indx }) }}>{item.title}</h2>
                )}
            </div>
            <div className='slider' id='slider'>
                {move ?
                    <>
                        <div className="slider-arrow slider-arrow-left" onClick={prev}>&#10094;</div>
                        <div className="slider-arrow slider-arrow-right" onClick={next}>&#10095;</div>
                    </>
                    : null
                }
                <div className='slider_inner' id='slider_inner' style={{ transform: `translate(${translate}px)` }}>
                    {slider ?//si existe info en slider
                        (
                            slider[proyecto]?.data.length > 0 ? //si hay imagenes en el proyecto seleccionado
                                (
                                    slider[proyecto].data.map((item, indx) =>
                                        <div className='item' id='item' key={indx}>
                                            {/* <div className='item' id='item' key={indx} ref={elRefs[indx]} onMouseOut={() => onChangeScroll(indx)}> */}
                                            <div>
                                                <img src={`${urlImages}uploads/experiencias/${item.img}`} />
                                            </div>
                                            {item.body ? 
                                            <h2>{item.body}</h2> : null}
                                        </div>)
                                )
                                :
                                (
                                    <div className='item' id='item'>
                                        {/* <h2>Ups!, aun no hay imágenes en este proyecto!</h2> */}
                                        <img src={`${urlImages}uploads/default.jpg`} />
                                    </div>
                                )
                        )
                        :
                        (<div className='item' id='item'>
                            <img src={`${urlImages}uploads/default.jpg`} />
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}

export default Slider;