import {
  GET_INFO_EXPERIENCIAS,
  EDITAR_EXPERIENCIAS
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case GET_INFO_EXPERIENCIAS:
      return {
        ...state,
        banner: action.payload.banner,
        slider: action.payload.slider,
        section1: action.payload.section1
      };
    case EDITAR_EXPERIENCIAS:
      return {
        ...state,
        banner: JSON.parse(action.payload.banner),
        slider: JSON.parse(action.payload.slider),
        section1: JSON.parse(action.payload.section1),
      };
    default:
      return state;
  }
};
