import React, { useContext } from 'react';
import inicioContext from '../../context/inicio/inicioContext';
import urlImages from '../../config/urlImages';
const Section1 = () => {
    const {section1} = useContext(inicioContext);

    return ( 
        <div className='section'>
            <div className='section_inner'>
                <div className='section_text'>
                    {section1 ? <h2>{section1.title}</h2> : null}
                    {section1 ?
                    <p>
                        {section1.body}
                    </p> : null}
                </div>
                <div className='section_img'>
                    {section1 ? <img src={`${urlImages}uploads/inicio/${section1?.img}`}/>: null}
                </div>
            </div>
        </div>
     );
}
 
export default Section1;