import React, { useContext, useEffect, useState } from 'react';
import contactenosContext from '../../context/contactenos/contactenosContext';
import { v4 } from 'uuid';
import authContext from '../../context/authentication/authContext';
const PanelConfiguracion = () => {

    const {usuario, cambiarPassword} = useContext(authContext);

    const [data, setData] = useState({
        newPass: "",
        retryNewPass: "",
        oldPass: ""
    })

    const {newPass, retryNewPass, oldPass} = data;

    const onChangeData = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const changePassword = (e) => {
        e.preventDefault();

        if(oldPass.trim() === ""){
            return;
        }
        if(newPass.trim() === "" || retryNewPass.trim() === ""){
            return;
        }
        if(newPass.trim() !== retryNewPass.trim()){
            return;
        }

        cambiarPassword({...data, username: usuario?.username});

    }

    return (
        <div className='panel_inicio'>
            <div className='panel_rows'>
                <div className='panel_items' style={{ backgroundColor: "var(--secundario_2)" }}>
                    <h2 style={{ color: "white" }}>configuración</h2>
                </div>
                <div className='panel_items'>
                    <h2>Sección 1: Cambio de contraseña</h2>
                    <form id="formulario-contactanos" onSubmit={changePassword}>
                        <div className='formulario_items'>
                            <input name="newPass" type="password" placeholder='Nueva contraseña' value={newPass} onChange={onChangeData}/>
                            <input name="retryNewPass" type="password" placeholder='Confirmar la nueva contraseña' value={retryNewPass} onChange={onChangeData}/>
                        </div>
                        <div className='formulario_items'>
                            <input name="oldPass" type="password" placeholder='Ingrese la contraseña antigua' value={oldPass} onChange={onChangeData}/>
                            <button>Modificar datos de contacto<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default PanelConfiguracion;