import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import urlImages from '../../config/urlImages';
import quienesSomosContext from '../../context/quienesSomos/quienesSomosContext';
import { useLocation, useNavigate  } from 'react-router-dom';
import authContext from '../../context/authentication/authContext';

const Section3 = () => {
    const navigate = useNavigate();

    const {enfoque, changeEnfoque} = useContext(authContext);
    const { section3 } = useContext(quienesSomosContext);
    const location = useLocation();
    const seccionEspecificaRef = useRef(null);
    useEffect(() => {
        // Accede al parámetro de la consulta llamado "seccion"
        const params = new URLSearchParams(location.search);
        const seccion = params.get('seccion');
    
        // Si se especifica una sección, intenta enfocarla
        if (seccion && seccionEspecificaRef.current) {
          seccionEspecificaRef.current.scrollIntoView({ behavior: 'smooth' });
          navigate("/quienessomos");
        }
      }, [location.search, seccionEspecificaRef]);
    const onLoadMiembros = () => {
        if(enfoque){
            seccionEspecificaRef.current.scrollIntoView({ behavior: 'smooth' })
            changeEnfoque();
        }
    } 

    return (
        <div className='section' id="miembros"  style={{textAlign: "center"}}>
            <h2 style={{fontWeight: "bold", fontSize: "50px"}} >Equipo de trabajo</h2>
            <div className='card-container' ref={seccionEspecificaRef}>
                {section3?.map((item, indx) => (
                    <div className='card' key={indx} style={{ backgroundImage: `url("${urlImages}uploads/quienes_somos/${item.img}")` }}>
                        <div className='image-info'>
                            {/* <p>{item.nombre}</p> */}
                            {/* <p>{item.cargo}</p> */}
                            <img src={`${urlImages}uploads/quienes_somos/${item.img}`} width={"0px"} onLoad={onLoadMiembros}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Section3;