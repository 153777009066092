import React, { Suspense, lazy, useContext, useEffect, useState } from 'react'
import queHacemosContext from '../../context/queHacemos/queHacemosContext';
import urlImages from '../../config/urlImages';
// import ImagenSlider from './ImagenSlider';
import LoadingImageSlider from '../layouts/LoadingImageSlider';

const ImagenSlider = lazy(() => import("./ImagenSlider"))

const colores = ["#fbbb44", "#1f8280", "#7bcad8", "#d7165b"]

const Portafolio = (props) => {

    const { lineas } = props;
    const { section1 } = useContext(queHacemosContext);
    const { portafolio } = useContext(queHacemosContext);

    const [info, setInfo] = useState({
        width: 0,
        maxWidth: 0,
        translate: 0,
        move: 0
    });

    const { width, maxWidth, translate, move } = info;

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        if (portafolio) {
            if (portafolio[lineas]?.length > 1) {
                setInfo({
                    ...info,
                    maxWidth: parseInt(document.getElementById('items_container').offsetWidth * portafolio[lineas]?.length),
                    width: parseInt(document.getElementById('items_container').offsetWidth),
                    translate: 0,
                    move: 1
                });
            } else {
                setInfo({
                    ...info,
                    maxWidth: parseInt(document.getElementById('items_container').offsetWidth * portafolio[lineas]?.length),
                    width: parseInt(document.getElementById('items_container').offsetWidth),
                    translate: 0,
                    move: 0
                });
            }
        }
        document.getElementById('items_container').style.transform = 'translateX(0px)';
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize)

        // console.log(dimensions)
        return _ => {
            window.removeEventListener('resize', handleResize)

        }
    }, [portafolio, dimensions, lineas]);

    const prev = () => {
        if (!move) {
            return;
        }
        const items_container = document.getElementById('items_container');
        if (translate - width >= 0) {//verificamos que el tamaño de el desplazamiento sea menor a el contenedor, para evitar que se siga desplazando y deje espacios vacios
            setInfo({
                ...info, translate: translate - width
            });
            items_container.style.transform = 'translateX(-' + parseInt(translate - width) + 'px)';//se usa la propiedad transform: translate para el movimiento
        } else {
            setInfo({
                ...info, translate: maxWidth - width
            });
            items_container.style.transform = 'translateX(-' + parseInt(maxWidth - width) + 'px)';//se usa la propiedad transform: translate para el movimiento
        }
    }

    const next = () => {
        if (!move) {
            return;
        }
        const items_container = document.getElementById('items_container');
        // console.log(info);
        if (translate + width < (maxWidth)) {
            setInfo({
                ...info, translate: translate + width
            });
            items_container.style.transform = 'translateX(-' + parseInt(translate + width) + 'px)';
        } else {
            setInfo({
                ...info, translate: 0
            });
            items_container.style.transform = 'translateX(-' + 0 + 'px)';
        }
    }

    useEffect(() => {
        //preloading image
        if (portafolio) {
            portafolio[lineas].forEach((face) => {
                const img = new Image();
                img.src = `${urlImages}uploads/que_hacemos/${face}`;
            })
        }
    }, [portafolio]);

    return (
        <div className='portafolio'>
            <h1 className='portafolio_title' style={{ color: `${colores[lineas]}` }}>{section1 ? section1[lineas].title : null}</h1>
            {/* <div className='slider'>
                <div className='slider_container'>
                    <div className='inner_slider'>
                        <div className="slider-arrow slider-arrow-left" onClick={onClickLeft}>&#10094;</div>
                        <Suspense fallback={<LoadingImageSlider />}>
                            {portafolio ? portafolio[lineas]?.map((item, idx) =>
                                currentSlide === idx ?
                                    <ImagenSlider key={idx} urlImages={urlImages} item={item} />
                                    : null
                            ) : null
                            }
                        </Suspense>
                        <div className="slider-arrow slider-arrow-right" onClick={onClickRight}>&#10095;</div>
                    </div>
                </div>
            </div> */}
            <div className='slider' id='slider'>
                <>
                    <div className="slider-arrow slider-arrow-left" onClick={prev}>&#10094;</div>
                    <div className="slider-arrow slider-arrow-right" onClick={next}>&#10095;</div>
                </>
                <div className='slider_inner' id='slider_inner'>
                    <div className='items_container' id='items_container' style={{ transform: `translate(-${translate}px)` }}>
                        {portafolio ?//si existe info en slider
                            (
                                portafolio[lineas]?.length > 0 ? //si hay imagenes en el proyecto seleccionado
                                    (
                                        portafolio[lineas].map((item, indx) =>
                                            <div className='item' id='item' key={indx}>
                                                {/* <div className='item' id='item' key={indx} ref={elRefs[indx]} onMouseOut={() => onChangeScroll(indx)}> */}
                                                <img src={`${urlImages}uploads/que_hacemos/${item}`} />
                                            </div>)
                                    )
                                    :
                                    (
                                        <div className='item' id='item'>
                                            {/* <h2>Ups!, aun no hay imágenes en este proyecto!</h2> */}
                                            <img src={`${urlImages}uploads/default.jpg`} />
                                        </div>
                                    )
                            )
                            :
                            (<div className='item' id='item'>
                                <img src={`${urlImages}uploads/default.jpg`} />
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Portafolio;