import React, { useContext, useEffect } from 'react';
    import authContext from '../context/authentication/authContext';
import ContainerLogin from '../components/signIn/ContainerLogin';

const SignIn = () => {

    const {msg} = useContext(authContext);
    useEffect(() => {
        console.log(msg)
    }, [msg])
    return (
        <div className='signin'>
            <ContainerLogin/>
            {msg ? <h2 className='alert'>{msg.msg}</h2> : null}
        </div>
    );
}

export default SignIn;