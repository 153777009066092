import { lazy, useContext, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "../routers/PrivateRoute";
import ErrorPage from "../pages/ErrorPage";
import authContext from "../context/authentication/authContext";

const Home = lazy(async () => {
    return Promise.all([
        import("../pages/inicio"),
    ]).then(([moduleExports]) => moduleExports);
});
const SignIn = lazy(async () => {
    return Promise.all([
        import("../pages/SignIn"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});
const Contactenos = lazy(async () => {
    return Promise.all([
        import("../pages/Contactenos"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});
const Donacion = lazy(async () => {
    return Promise.all([
        import("../pages/Donacion"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});
const Experiencias = lazy(async () => {
    return Promise.all([
        import("../pages/Experiencias"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});
const PanelAdmin = lazy(async () => {
    return Promise.all([
        import("../pages/PanelAdmin"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});
const QueHacemos = lazy(async () => {
    return Promise.all([
        import("../pages/QueHacemos"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});
const QuienesSomos = lazy(async () => {
    return Promise.all([
        import("../pages/QuienesSomos"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});
const Regimen = lazy(async () => {
    return Promise.all([
        import("../pages/Regimen"),
        new Promise((resolve) => setTimeout(resolve, 1300)),
    ]).then(([moduleExports]) => moduleExports);
});

const Navigator = () => {
    const { usuario, autenticado, usuarioAutenticado } = useContext(authContext);
    useEffect(() => {
        usuarioAutenticado();
    }, [])

    let location = useLocation();
    return (
        <Routes>
            <Route
                path={'/'}
                element={<Home />}
                exact
            />
            <Route
                path={'/signin'}
                element={<SignIn />}
                exact
            />
            <Route
                path={'/contactenos'}
                element={<Contactenos />}
                exact
            />
            <Route
                path={'/donacion'}
                element={<Donacion />}
                exact
            />
            <Route
                path={'/experiencias'}
                element={<Experiencias />}
                exact
            />
            <Route
                path={'/quehacemos'}
                element={<QueHacemos />}
                exact
            />
            <Route
                path={'/quienessomos'}
                element={<QuienesSomos />}
                exact
            />
            <Route
                path={'/regimen'}
                element={<Regimen />}
                exact
            />
            <Route
                path={'/'}
                element={
                    <PrivateRoute />
                }
            >
                <Route
                    path={'/panel'}
                    element={<PanelAdmin />}
                    exact
                />
            </Route>
            {/* <Route
                path={'/signin'}
                element={
                    <PrivateRoute />
                }
            >
                <Route
                    path={'/panel'}
                    element={
                        <PanelAdmin />
                    }
                    exact
                />
            </Route> */}
            <Route
                path="*"
                element={
                    <ErrorPage />
                }
            />
        </Routes>
    );
}

export default Navigator;