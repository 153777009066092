import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {

  return (
    <div id="error-page" style={{textAlign: "center", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center'}}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        La página que está buscando no existe
      </p>
      <Link id="regresar_inicio" to={'/'}>Ir a inicio</Link>
    </div>
  );
}