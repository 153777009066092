import React, { useContext, useEffect } from 'react';
import inicioContext from '../../context/inicio/inicioContext';
import urlImages from '../../config/urlImages';

const Banner = () => {
    const { banner } = useContext(inicioContext);
    // useEffect(() => {
    //     // console.log(banner)
    // }, [banner])
    return (
        <div className="banner_secundario" style={{backgroundImage: `url("${urlImages}uploads/inicio/${banner?.img}")`}}>
            <div className='slogan'>
                {banner ? <h1>
                    {banner?.title}
                </h1> : null}
            </div>
        </div>
    );
}

export default Banner;