import React, { useContext, useEffect } from 'react';
import Header from '../components/layouts/Header';
import Banner from '../components/quienesSomos/Banner';
import Section1 from '../components/quienesSomos/Section1';
import Section2 from '../components/quienesSomos/Section2';
import Footer from '../components/layouts/Footer';
import quienesSomosContext from '../context/quienesSomos/quienesSomosContext';
import Section3 from '../components/quienesSomos/Section3';

const QuienesSomos = () => {
    const { getQuienesSomos } = useContext(quienesSomosContext);

    useEffect(() => {
        getQuienesSomos();
    }, []);
    
    return (
        <div className='quienes_somos'>
            <Header />
            <Banner/>
            <Section1/>
            <Section2/>
            <Section3/>
            <Footer/>
        </div>
    );
}

export default QuienesSomos;