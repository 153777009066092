import React, { useContext, useEffect, useState } from 'react'
import authContext from '../../context/authentication/authContext';
import { useLocation, useNavigate } from 'react-router-dom';

const ContainerLogin = () => {
    const { autenticado, msg, signIn } = useContext(authContext);

    let location = useLocation();
    let navigate = useNavigate();

    let from = location.state?.from?.pathname || '/panel';

    //useEffect para validar si existe una sesion abierta
    useEffect(() => {
        if (autenticado) navigate(from, { replace: true });
    }, [autenticado, navigate, from]);

    useEffect(() => {
        if (autenticado) {
            navigate('/panel')
        }
    }, [autenticado])

    //State para iniciar sesión
    const [user, guardarUser] = useState({
        username: "",
        password: "",
    });

    const { username, password } = user;

    const onChangeUser = (e) => {
        guardarUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const handlerSubmit = (e) => {
        e.preventDefault();
        //Validar que no haya campos vacios
        if (username.trim() === "" || password.trim() === "") {
            // mostrarAlerta('Todos los campos son obligatorios', 'btn-log btn-warning mb-1');
            return;
        }

        // if (msg) mostrarAlerta(msg?.msg, msg?.categoria)

        //Pasarlo al action
        signIn({ username, password });
    };

    return (
        <div className='login'>
            <form autoComplete='off' onSubmit={handlerSubmit}>
                <div className='title'>
                    <h2>Iniciar sesión</h2>
                </div>
                <div className='content'>
                    <input type='text' name='username' placeholder='Nombre de usuario' value={username} onChange={onChangeUser} />
                    <input type='password' name='password' placeholder='Contraseña' value={password} onChange={onChangeUser} />
                </div>
                <button type='submit'>Iniciar sesión</button>
            </form>
        </div>
    );
}

export default ContainerLogin;