import React, { useContext } from 'react';
import quienesSomosContext from '../../context/quienesSomos/quienesSomosContext';
const Section2 = () => {

    const { section2 } = useContext(quienesSomosContext);

    return (
        <div className='section section2'>
            <div className='section_inner'>
                {section2?.map((item, indx) => 
                <div className='section_text' key={indx}>
                    <h2>{item.title}</h2>
                    <div>
                        <p>
                            {item.body}
                        </p>
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default Section2;