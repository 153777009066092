import React, { useContext, useEffect } from 'react';
import Logo from '../../img/1.png';
import img1 from '../../img/lineas1_nobg.png';
import img2 from '../../img/lineas2_nobg.png';
import img3 from '../../img/lineas3_nobg.png';
import img4 from '../../img/lineas4_nobg.png';
import lineas from '../../img/lineas_de_accion.png';
import queHacemosContext from '../../context/queHacemos/queHacemosContext';

const Section1 = (props) => {

    const {setLineas} = props;
 
    const { section1 } = useContext(queHacemosContext);
    // useEffect(() => {
    //     console.log(section1);
    // }, [section1]);

    const row1_in = () => {
        const row = document.getElementsByClassName("row_1");
        row[0].style.opacity = 1;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "entrando_imagen 1s";
        row[0].style.zIndex = "999 !important";
        row[0].style.position = "relative";
    }
    const row1_out = () => {
        const row = document.getElementsByClassName("row_1");
        row[0].style.opacity = 0;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "saliendo_imagen 1s";
    }
    const row2_in = () => {
        const row = document.getElementsByClassName("row_3");
        row[0].style.opacity = 1;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "entrando_imagen_izq 1s";
        row[0].style.zIndex = "999 !important";
        row[0].style.position = "relative";
    }
    const row2_out = () => {
        const row = document.getElementsByClassName("row_3");
        row[0].style.opacity = 0;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "saliendo_imagen_izq 1s";
    }
    const row3_in = () => {
        const row = document.getElementsByClassName("row_2");
        row[0].style.opacity = 1;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "entrando_imagen 1s";
    }
    const row3_out = () => {
        const row = document.getElementsByClassName("row_2");
        row[0].style.opacity = 0;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "saliendo_imagen 1s";
        row[0].style.zIndex = "999 !important";
        row[0].style.position = "relative";
    }
    const row4_in = () => {
        const row = document.getElementsByClassName("row_4");
        row[0].style.opacity = 1;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "entrando_imagen_izq 1s";
        row[0].style.zIndex = "999 !important";
        row[0].style.position = "relative";
    }
    const row4_out = () => {
        const row = document.getElementsByClassName("row_4");
        row[0].style.opacity = 0;
        row[0].style.transition = "ease 1s";
        row[0].style.animation = "saliendo_imagen_izq 1s";
    }


    return (
        <div className='section_que_hacemos'>
            <img className='lineas_de_accion' src={lineas} />
            {section1 ?
                <div className='que_hacemos_container'>
                    <div className='col_1'>
                        <div className='row_1'>
                            <h2>{section1[0].title}</h2>
                            {/* <p>{section1[0].body}</p> */}
                        </div>
                        <div className='row_2'>
                            <h2>{section1[2]?.title}</h2>
                            {/* <p>{section1[2]?.body}</p> */}
                        </div>
                    </div>
                    <div className='col_2'>
                        {/* <img className='lineas_de_accion' src={lineas} /> */}
                        <div className='lineas_container'>
                            <img className='logo' src={Logo} />
                            <div className='lineas_accion'>
                                <img src={img1} onMouseOver={row1_in} onMouseOut={row1_out} onClick={() => setLineas(0)}/>
                                <img src={img2} onMouseOver={row2_in} onMouseOut={row2_out} onClick={() => setLineas(1)}/>
                            </div>
                            <div className='lineas_accion'>
                                <img src={img3} onMouseOver={row3_in} onMouseOut={row3_out} onClick={() => setLineas(2)}/>
                                <img src={img4} onMouseOver={row4_in} onMouseOut={row4_out} onClick={() => setLineas(3)}/>
                            </div>
                        </div>
                    </div>
                    <div className='col_3'>
                        <div className='row_3'>
                            <h2>{section1[1].title}</h2>
                            {/* <p>{section1[1].body}</p> */}
                        </div>
                        <div className='row_4'>
                            <h2>{section1[3].title}</h2>
                            {/* <p>{section1[3].body}</p> */}
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    );
}

export default Section1;