import React, { useContext, useEffect, useState } from 'react';
import queHacemosContext from '../../context/queHacemos/queHacemosContext';
import { v4 } from 'uuid';
import urlImages from '../../config/urlImages';
const PanelQueHacemos = () => {

    const { banner, section1, portafolio, section2, updateQueHacemos } = useContext(queHacemosContext)

    const [archivo, setArchivo] = useState(null);

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [bannerEdit, setBannerEdit] = useState({});
    const changeInfoBanner = (e) => {
        e.preventDefault();
        const f = new FormData();
        updateQueHacemos(f, {
            banner: bannerEdit
        });
    }
    const changeImagenBanner = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setBannerEdit({
            ...bannerEdit,
            img: newName
        })
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateQueHacemos(f, {
            banner: {
                ...bannerEdit,
                img: newName
            }
        });
        setArchivo(null);
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [section1Edit, setSection1Edit] = useState([]);
    const [idSection1, setIdSection1] = useState("");
    const changeInfoSection1 = (e) => {
        e.preventDefault();
        const f = new FormData();
        // console.log(section1Edit.title)
        updateQueHacemos(f, {
            section1: section1Edit
        });
    }

    const onChangeSection1 = (e) => {
        setSection1Edit(section1Edit.map((item, indx) => indx == idSection1 ? { ...item, [e.target.name]: e.target.value } : item))
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [portafolioEdit, setPortafolioEdit] = useState([]);
    const [idPortafolio, setIdPortafolio] = useState("");
    const [portafolioDelete, setPortafolioDelete] = useState('');
    const addPortafolio = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        // setPortafolioEdit([
        //     ...portafolioEdit,
        //     newName
        // ])
        setPortafolioEdit(portafolioEdit.map((item, indx) => indx === parseInt(idPortafolio) ? [...item, newName] : item));
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateQueHacemos(f, {
            portafolio: portafolioEdit.map((item, indx) => indx === parseInt(idPortafolio) ? [...item, newName] : item)
        });
    }
    const deletePortafolio = (e) => {
        e.preventDefault();
        setPortafolioEdit(portafolioEdit.map((item, indx) => indx === parseInt(idPortafolio) ? item.filter((_, indx) => indx !== parseInt(portafolioDelete)) : item));
        const f = new FormData();
        updateQueHacemos(f, {
            portafolio: portafolioEdit.map((item, indx) => indx === parseInt(idPortafolio) ? item.filter((_, indx) => indx !== parseInt(portafolioDelete)) : item)
        });
        setPortafolioDelete('');
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const changeImagenSection2 = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;

        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateQueHacemos(f, {
            section2: {
                img: newName
            }
        });
        // setArchivo(null); 
    }

    const subirArchivo = (e) => {
        setArchivo(e);
    }

    useEffect(() => {
        setBannerEdit(banner);
        setSection1Edit(section1);
        setPortafolioEdit(portafolio);
    }, [banner]);

    return (
        <div className='panel_inicio'>
            <div className='panel_rows'>
                <div className='panel_items' style={{ backgroundColor: "var(--secundario_2)" }}>
                    <h2 style={{ color: "white" }}>Que Hacemos</h2>
                </div>
                <div className='panel_items'>
                    <h2>Sección 1</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoBanner}>
                        <div className='formulario_items'>
                            <textarea name="title" type="text" placeholder='Ingrese el Titulo de la sección' value={bannerEdit?.title} onChange={(e) => setBannerEdit({ ...bannerEdit, title: e.target.value })} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="nombres" type="text" placeholder="Contenido de la sección" maxLength="1000" value={bannerEdit?.body} onChange={(e) => setBannerEdit({ ...bannerEdit, body: e.target.value })} />
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={changeImagenBanner}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 2: Líneas de acción</h2>
                    <form id="formulario-contactanos" >
                        <div className='formulario_items'>
                            <select className="identificacion" name="identificacion" value={idSection1} onChange={(e) => setIdSection1(e.target.value)}>
                                <option value="">Seleccione un item a modificar</option>
                                {section1Edit ? section1Edit.map((item, indx) =>
                                    <option key={indx} value={indx}>{item.title}</option>
                                ) : null
                                }
                            </select>
                        </div>
                    </form>
                    {idSection1 !== "" ?
                        <form id="formulario-contactanos" onSubmit={changeInfoSection1}>
                            <div className='formulario_items'>
                                <textarea name="title" type="text" placeholder='Ingrese el Titulo de la sección' value={section1Edit[idSection1]?.title} onChange={onChangeSection1} />
                                <button>Modificar item seleccionado<i className="fa-solid fa-play fa-2xs"></i></button>
                            </div>
                            {/* <div className='formulario_items'>
                            <textarea name="body" type="text" placeholder="Contenido de la sección" maxLength="1000" value={section1Edit[idSection1]?.body} onChange={onChangeSection1}/>
                        </div> */}
                        </form>
                        : null}
                </div>
                <div className='panel_items'>
                    <h2>Portafolios</h2>
                    <form id="formulario-contactanos" >
                        <div className='formulario_items'>
                            <select className="identificacion" name="identificacion" value={idPortafolio} onChange={(e) => setIdPortafolio(e.target.value)}>
                                <option value="">Seleccione un item a modificar</option>
                                {section1Edit ? section1Edit.map((item, indx) =>
                                    <option key={indx} value={indx}>{item.title}</option>
                                ) : null
                                }
                            </select>
                        </div>
                    </form>
                    {idPortafolio !== "" ?
                        <>
                            <form id="formulario-contactanos" onSubmit={deletePortafolio}>
                                <div className='formulario_items'>
                                    <select className="identificacion" name="identificacion" value={portafolioDelete} onChange={(e) => setPortafolioDelete(e.target.value)}>
                                        <option value="">Seleccione una imagen</option>
                                        {portafolioEdit[idPortafolio]?.map((item, indx) =>
                                            <option value={indx} key={indx}>{indx + 1}</option>
                                        )}
                                    </select>
                                    {portafolioDelete != '' ? <img className='img_preview' src={`${urlImages}uploads/que_hacemos/${portafolioEdit[idPortafolio][portafolioDelete]}`} alt="Preview"/> : null}
                                    <button style={{ backgroundColor: "var(--secundario_2)" }}>Borrar Imagen Seleccionada<i className="fa-solid fa-play fa-2xs"></i></button>
                                </div>
                            </form>
                            <hr />
                            <form id="formulario-contactanos" onSubmit={addPortafolio}>
                                <div className='formulario_items'>
                                    <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                                    <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                                </div>
                            </form>
                        </> : null}
                </div>
                {/* <div className='panel_items'>
                    <h2>Sección 3</h2>
                    <form id="formulario-contactanos" onSubmit={changeImagenSection2}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])}/>
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div> */}
            </div>
        </div>
    );
}

export default PanelQueHacemos;