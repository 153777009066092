import React from 'react';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Section from '../components/donacion/Section';
import Banner from '../components/donacion/Banner';

const Donacion = () => {
    return (
        <div className='donacion'>
            <Header/>
            <Banner/>
            <Section/>
            <Footer/>
        </div>
    );
}

export default Donacion;