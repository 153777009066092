import React, { useContext, useEffect, useState } from 'react';
import experienciasContext from '../../context/experiencias/experienciasContext';
import { v4 } from 'uuid';
import urlImages from '../../config/urlImages';
const PanelExperiencias = () => {

    const { banner, slider, section1, getExperiencias, updateExperiencias } = useContext(experienciasContext);

    const [archivo, setArchivo] = useState(null);
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [bannerEdit, setBannerEdit] = useState({});
    const changeInfoBanner = (e) => {
        e.preventDefault();
        const f = new FormData();
        updateExperiencias(f, {
            banner: bannerEdit
        });
    }
    const changeImagenBanner = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setBannerEdit({
            ...bannerEdit,
            img: newName
        })
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateExperiencias(f, {
            banner: {
                ...bannerEdit,
                img: newName
            }
        });
        setArchivo(null);
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [sliderEdit, setSliderEdit] = useState([]);
    const [newProyecto, setNewProyecto] = useState("");
    const addProyect = (e) => {
        e.preventDefault();
        const f = new FormData();
        updateExperiencias(f, {
            slider: [...sliderEdit, { title: newProyecto, data: [] }]
        });
        setNewProyecto("");
    }
    const [proyectEdit, setProyectEdit] = useState("")
    const deleteProyect = (e) => {
        e.preventDefault();
        setSliderEdit(sliderEdit.filter((_, indx) => indx !== parseInt(proyectEdit)));
        const f = new FormData();
        updateExperiencias(f, {
            slider: sliderEdit.filter((_, indx) => indx !== parseInt(proyectEdit))
        });
    }
    const [imgEdit, setImgEdit] = useState("")
    const setImgDesc = (e) => {
        setSliderEdit(sliderEdit.map((item, indx) => indx === parseInt(proyectEdit) ? { ...item, data: item.data.map((item2, indx2) => indx2 === parseInt(imgEdit) ? {...item2, body: e.target.value} : item2) } : item))
    }
    const deleteImg = (e) => {
        e.preventDefault();
        setSliderEdit(sliderEdit.map((item, indx) => indx === parseInt(proyectEdit) ? { ...item, data: item.data.filter((_, indx2) => indx2 !== parseInt(imgEdit)) } : item));
        const f = new FormData();
        updateExperiencias(f, {
            slider: sliderEdit.map((item, indx) => indx === parseInt(proyectEdit) ? { ...item, data: item.data.filter((_, indx2) => indx2 !== parseInt(imgEdit)) } : item)
        });
        setImgEdit('');
    }
    const updateImgDesc = (e) => {
        e.preventDefault();
        setSliderEdit(sliderEdit);
        const f = new FormData();
        updateExperiencias(f, {
            slider: sliderEdit
        });
    }
    const addImg = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setSliderEdit(sliderEdit.map((item, indx) => indx === parseInt(proyectEdit) ? { ...item, data: [...item.data, { img: newName, body: "" }] } : item));
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateExperiencias(f, {
            slider: sliderEdit.map((item, indx) => indx === parseInt(proyectEdit) ? { ...item, data: [...item.data, { img: newName, body: "" }] } : item)
        });
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [section1Edit, setSection1Edit] = useState([])
    const deleteComent = (e, id) => {
        e.preventDefault();
        setSection1Edit(section1Edit.filter((_, indx) => indx !== Number(id)));
        const f = new FormData();
        updateExperiencias(f, {
            section1: section1Edit.filter((_, indx) => indx !== Number(id))
        });
    }

    const subirArchivo = (e) => {
        setArchivo(e);
    }

    useEffect(() => {
        setBannerEdit(banner);
        setSliderEdit(slider);
        setSection1Edit(section1);
    }, [banner, slider, section1]);

    return (
        <div className='panel_inicio'>
            <div className='panel_rows'>
                <div className='panel_items' style={{ backgroundColor: "var(--secundario_2)" }}>
                    <h2 style={{ color: "white" }}>Experiencias</h2>
                </div>
                <div className='panel_items'>
                    <h2>Sección 1</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoBanner}>
                        <div className='formulario_items'>
                            <textarea name="title" type="text" placeholder='Ingrese el Titulo de la sección' value={bannerEdit?.title} onChange={(e) => setBannerEdit({ ...bannerEdit, title: e.target.value })} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="nombres" type="text" placeholder="Contenido de la sección" maxLength="1000" value={bannerEdit?.body} onChange={(e) => setBannerEdit({ ...bannerEdit, body: e.target.value })} />
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={changeImagenBanner}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 2: Proyectos</h2>
                    <form id="formulario-contactanos" onSubmit={addProyect}>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el nombre del nuevo proyecto' value={newProyecto} onChange={(e) => setNewProyecto(e.target.value)} />
                            <button>Crear nuevo proyecto<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                    <form id="formulario-contactanos" onSubmit={deleteProyect}>
                        <div className='formulario_items'>
                            <select className="identificacion" name="identificacion" value={proyectEdit} onChange={(e) => {setProyectEdit(e.target.value); setImgEdit("")}}>
                                <option value="">Seleccione un proyecto a modificar</option>
                                {
                                    slider?.map((item, indx) =>
                                        <option value={indx} key={indx}>{item.title}</option>
                                    )
                                }
                            </select>
                            {proyectEdit !== "" ?
                                <button style={{ backgroundColor: "var(--secundario_2)" }}>Borrar proyecto seleccionado<i className="fa-solid fa-play fa-2xs"></i></button>
                                : null
                            }
                        </div>
                    </form>
                    {
                        proyectEdit !== "" ?
                            (
                                <>
                                    <form id="formulario-contactanos" onSubmit={deleteImg}>
                                        <div className='formulario_items'>
                                            <select className="identificacion" name="identificacion" value={imgEdit} onChange={(e) => setImgEdit(e.target.value)}>
                                                <option value="">Seleccione una imagen a modificar</option>
                                                {
                                                    slider[proyectEdit]?.data.map((item, indx) =>
                                                        <option value={indx} key={indx}>{indx + 1}</option>
                                                    )
                                                }
                                            </select>
                                            {imgEdit != '' ? <img className='img_preview' src={`${urlImages}uploads/experiencias/${slider[proyectEdit].data[imgEdit].img}`} alt="Preview"/> : null}
                                            <button style={{ backgroundColor: "var(--secundario_2)" }}>Borrar imagen seleccionada<i className="fa-solid fa-play fa-2xs"></i></button>
                                        </div>
                                    </form>
                                    {
                                        imgEdit !== "" ?
                                            <form id="formulario-contactanos" onSubmit={updateImgDesc}>
                                                <div className='formulario_items'>
                                                    <textarea name="body" type="text" style={{width: "50%"}} placeholder="Descripción" maxLength="1000" value={sliderEdit[proyectEdit].data[imgEdit]?.body} onChange={setImgDesc} />
                                                    <button>Actualizar descripción de la imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                                                </div>
                                            </form> : null
                                    }
                                    <form id="formulario-contactanos" onSubmit={addImg}>
                                        <div className='formulario_items'>
                                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                                            <button>Subir Imagen al Proyecto Seleccionado<i className="fa-solid fa-play fa-2xs"></i></button>
                                        </div>
                                    </form>
                                </>
                            ) : null
                    }
                </div>
                <div className='panel_items'>
                    <h2>Sección 3: Comentarios</h2>
                    <form id="formulario-contactanos" >
                        <div className='formulario_items'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Comentario</th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        section1Edit?.map((item, indx) =>
                                            <tr key={indx}>
                                                <th scope="row">{indx + 1}</th>
                                                <td><textarea name="nombres" readOnly value={item.body} /></td>
                                                <td>{item.createdAt}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <button style={{ backgroundColor: "var(--secundario_2)" }} onClick={(e) => deleteComent(e, indx)}>Eliminar<i className="fa-solid fa-play fa-2xs"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default PanelExperiencias;