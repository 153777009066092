import {
  GET_INFO_QUE_HACEMOS,
  EDITAR_QUE_HACEMOS
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case GET_INFO_QUE_HACEMOS:
      return {
        ...state,
        banner: action.payload.banner,
        section1: action.payload.section1,
        portafolio: action.payload.portafolio,
        section2: action.payload.section2
      };
    case EDITAR_QUE_HACEMOS:
      return {
        ...state,
        banner: JSON.parse(action.payload.banner),
        section1: JSON.parse(action.payload.section1),
        portafolio: JSON.parse(action.payload.portafolio),
        section2: JSON.parse(action.payload.section2),
      };
    default:
      return state;
  }
};
