import React, { useEffect, useState } from 'react'
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Documento1 from '../documents/ACTA_ASAMBLEA_01_2023.pdf';
import Documento2 from '../documents/ACTA_DE_CONSTITUCION_FUNDACION_SOLIDARE.pdf';
import Documento3 from '../documents/CAMARA_DE_COMERCIO_09_2023.pdf';
import Documento4 from '../documents/CERTIFICADOS_JUDICIALES_SOLIDARE.pdf';
import Documento5 from '../documents/Estados_financieros_2022_12_SOLIDARE.pdf';
import Documento6 from '../documents/ESTATUTO_FUNDACION_SOLIDARE_2023.pdf';

const Regimen = () => {

    const [altura, setAltura] = useState(0);
    const [documento, setDocumento] = useState(0);

    useEffect(() => {
        setAltura(document.getElementById('wraper').offsetHeight);

        function handleResize() {
            setAltura(document.getElementById('wraper').offsetHeight);
        }
        window.addEventListener('resize', handleResize)
    }, [])

    const handlerOnClick = (n) => {
        setDocumento(n);
    }
    return (
        <div className='regimen'>
            <Header />
            <div className='inner_regimen' style={{ marginTop: `${altura}px` }}>
                <div className='sidebar'>
                    <div className='inner_sidebar'>
                        <ul>
                            <li className={documento === 0 ? 'regimen_active' : ''} onClick={() => handlerOnClick(0)}>ACTA ASAMBLEA 01-2023</li>
                            <li className={documento === 1 ? 'regimen_active' : ''} onClick={() => handlerOnClick(1)}>ACTA DE CONSTITUCIÓN - FUNDACIÓN SOLIDARE</li>
                            <li className={documento === 2 ? 'regimen_active' : ''} onClick={() => handlerOnClick(2)}>CÁMARA DE COMERCIO 09-2023</li>
                            <li className={documento === 3 ? 'regimen_active' : ''} onClick={() => handlerOnClick(3)}>CERTIFICADOS JUDICIALES - SOLIDARE</li>
                            <li className={documento === 4 ? 'regimen_active' : ''} onClick={() => handlerOnClick(4)}>Estados financieros 2022 12 SOLIDARE</li>
                            <li className={documento === 5 ? 'regimen_active' : ''} onClick={() => handlerOnClick(5)}>ESTATUTO FUNDACIÓN SOLIDARE 2023</li>
                        </ul>
                    </div>
                </div>
                <div className='documentos'>
                    <div className='inner_documentos'>
                        { documento === 0 ?
                            <iframe src={Documento1} title='Documento PDF' width="100%" height="800px"></iframe>
                        : null}
                        {documento === 1 ?
                            <iframe src={Documento2} title='Documento PDF 2' width="100%" height="800px"></iframe>
                            : null}
                        {documento === 2 ?
                            <iframe src={Documento3} title='Documento PDF 3' width="100%" height="800px"></iframe>
                            : null}
                        {documento === 3 ?
                            <iframe src={Documento4} title='Documento PDF 4' width="100%" height="800px"></iframe>
                            : null}
                        {documento === 4 ?
                            <iframe src={Documento5} title='Documento PDF 5' width="100%" height="800px"></iframe>
                            : null}
                        {documento === 5 ?
                            <iframe src={Documento6} title='Documento PDF 6' width="100%" height="800px"></iframe>
                            : null}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Regimen;