import React from 'react';

const Banner = () => {
    return (
        <section id="banner_quienes_somos"> 
            <div>
                <h1>Lorem Ipsum Dolor</h1>
                <p>
                    Lorem ipsum dolor sit amett, consectetur adipiscing elit. Ut vitae nisl et enim mattis gravida.
                </p>
            </div>
        </section>
    );
}

export default Banner;