import React, { useContext, useEffect, useState } from 'react';
import contactenosContext from '../../context/contactenos/contactenosContext';
import { v4 } from 'uuid';
const PanelContactenos = () => {

    const { banner, section1, updateContactenos } = useContext(contactenosContext);

    const [archivo, setArchivo] = useState(null);
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [bannerEdit, setBannerEdit] = useState({});
    const changeInfoBanner = (e) => {
        e.preventDefault();
        const f = new FormData();
        updateContactenos(f, {
            banner: bannerEdit
        });
    }
    const changeImagenBanner = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setBannerEdit({
            ...bannerEdit,
            img: newName
        })
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateContactenos(f, {
            banner: {
                ...bannerEdit,
                img: newName
            }
        });
        setArchivo(null);
    }

    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [section1Edit, setSection1Edit] = useState({})
    const changeInfoSection1 = (e) => {
        e.preventDefault();
        const f = new FormData();
        updateContactenos(f, {
            section1: section1Edit
        });
    }

    const subirArchivo = (e) => {
        setArchivo(e);
    }

    useEffect(() => {
        setBannerEdit(banner);
        setSection1Edit(section1);
    }, [banner, section1]);

    return (
        <div className='panel_inicio'>
            <div className='panel_rows'>
                <div className='panel_items' style={{ backgroundColor: "var(--secundario_2)" }}>
                    <h2 style={{ color: "white" }}>Contáctenos</h2>
                </div>
                <div className='panel_items'>
                    <h2>Sección 1</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoBanner}>
                        <div className='formulario_items'>
                            <textarea name="title" type="text" placeholder='Ingrese el Titulo de la sección' value={bannerEdit?.title} onChange={(e) => setBannerEdit({ ...bannerEdit, title: e.target.value })}/>
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="nombres" type="text" placeholder="Contenido de la sección" maxLength="1000" value={bannerEdit?.body} onChange={(e) => setBannerEdit({ ...bannerEdit, body: e.target.value })}/>
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={changeImagenBanner}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])}/>
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 2: Datos de contacto</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoSection1}>
                        <div className='formulario_items'>
                            <textarea name="nombre" type="text" placeholder='Ingrese el Nombre' value={section1Edit?.nombre} onChange={(e) => setSection1Edit({ ...section1Edit, nombre: e.target.value })}/>
                            <textarea name="cell" type="number" placeholder='Ingrese el Teléfono' value={section1Edit?.cell} onChange={(e) => setSection1Edit({ ...section1Edit, cell: e.target.value })}/>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="email" type="text" placeholder='Ingrese el Correo'value={section1Edit?.email} onChange={(e) => setSection1Edit({ ...section1Edit, email: e.target.value })} />
                            <textarea name="redes" type="text" placeholder='Ingrese las redes sociales' value={section1Edit?.redes} onChange={(e) => setSection1Edit({ ...section1Edit, redes: e.target.value })}/>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="direccion" type="text" placeholder='Ingrese la dirección' value={section1Edit?.dir} onChange={(e) => setSection1Edit({ ...section1Edit, dir: e.target.value })}/>
                            <button>Modificar datos de contacto<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                    {/* <hr/>
                    <form id="formulario-contactanos" >
                        <div className='formulario_items'>
                            <input name="contact_email" type="text" placeholder='Ingrese el Correo de Contacto' />
                            <button>Modificar<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form> */}
                </div>
            </div>
        </div>
    );
}

export default PanelContactenos;