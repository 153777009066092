import React, { useContext, useEffect } from 'react';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Banner from '../components/contactenos/Banner';
import Section from '../components/contactenos/Section';
import contactenosContext from '../context/contactenos/contactenosContext';

const Contactenos = () => {

    const { getContactenos } = useContext(contactenosContext)

    useEffect(() => {
        getContactenos();
    }, [])

    return (
        <div className='contactenos'>
            <Header/>
            <Banner/>
            <Section/>
            <Footer/>
        </div>
    );
}

export default Contactenos;