import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <div className="footer" style={{ fontWeight: "bold" }}>
                <div>
                    <p style={{ marginBottom: '0', fontSize: "15px" }}>© 2023 SOLIDARE. TODOS LOS DERECHOS RESERVADOS</p>
                    <p style={{ marginBottom: '0', fontSize: "12px" }}><a href='https://midgar.artosh.dev/landing' target='_blank' style={{textDecoration: "none", color: "var(--secundario)"}}>DISEÑADO POR MIDGAR</a></p>
                </div>
                {/* <NavLink className="navbar-nav" to='/regimen'>RÉGIMEN TRIBUTARIO Y ESTADOS FINANCIEROS</NavLink> */}
                <a>Aviso de privacidad</a>
            </div>
        </footer>
    );
}

export default Footer;