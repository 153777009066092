import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';

import ContactenosContext from './contactenosContext';
import ContactenosReducer from './contactenosReducer';

import {
    GET_INFO_CONTACTENOS,
    EDITAR_CONTACTENOS,
    GET_INFO_ERROR
} from '../../types';
import clienteAxios2 from '../../config/axiosUpload';

const ContactenosState = props => {

    const initialState = {
        banner: null,
        section1: null
    }
    const [state, dispatch] = useReducer(ContactenosReducer, initialState);

    const getContactenos = async () => {
        try {
            const respuesta = await clienteAxios.get(`/contactenos`);
            // console.log(respuesta.data.data);
            dispatch({
                type: GET_INFO_CONTACTENOS,
                payload: respuesta.data.data
            })
        } catch (error) {
            dispatch({
                type: GET_INFO_ERROR,
                payload: error.response.data.msg
            })
        }
    }

    const updateContactenos = async (file, newData) => {
        let data = {
            banner: state.banner,
            section1: state.section1
        }

        data = {...data, ...newData};
        // console.log(data);
        file.append("data", JSON.stringify(data));
        file.append("ruta", "contactenos");

        try {
            const respuesta = await clienteAxios.put(`/contactenos`, file);
            const respuesta2 = await clienteAxios2.post(`/upload`, file);

            dispatch({
                type: EDITAR_CONTACTENOS,
                payload: respuesta.data
            })
        } catch (error) {
            console.log(error)
        }
    }
    
    return (
        <ContactenosContext.Provider
            value={{
                banner: state.banner,
                section1: state.section1,
                getContactenos,
                updateContactenos,
            }}
        >
            {props.children}
        </ContactenosContext.Provider>
    )
}

export default ContactenosState;