import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';

import AuthContext from './authContext';
import AuthReducer from './authReducer';

import {
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    REGISTRO_ERROR,
    USUARIO_AUTENTICADO,
    CERRAR_SESION,
    CHANGE_PASSWORD_EXITOSO,
    MSG_RESET,
    CHANGE_ENFOQUE
} from '../../types';

const AuthState = props => {

    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        msg: null,
        cargando: true,
        enfoque: false
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState);

    const signIn = async datos => {
        // console.log(datos)
        try {
            const respuesta = await clienteAxios.post('/auth', datos);
            // console.log(respuesta.data)
            localStorage.setItem('token', respuesta.data.token);
            dispatch({
                type: LOGIN_EXITOSO,
                payload: respuesta.data
            })
            usuarioAutenticado();
        } catch (error) {
            console.log(error.message)
            const msg = {
                msg: error.response.data.msg,
                categoria: 'btn-log btn-warning mb-1'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: msg
            })
            setTimeout(msgReset, 3000);
        }
    }

    // const signUp = async (datos, id_inst) => {
    //     try {
    //         const respuesta = await clienteAxios.post(`/usuarios/${id_inst}`, datos);
    //         // dispatch({
    //         //     type: REGISTRO_EXITOSO,
    //         //     payload: respuesta.data
    //         // })
    //     } catch (error) {
    //         const msg = {
    //             msg: error.response.data.msg,
    //             categoria: 'btn-log btn-warning mb-1'
    //         }
    //         dispatch({
    //             type: REGISTRO_ERROR,
    //             payload: msg
    //         })
    //     }
    // }

    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('token');
        
        if (token) {
            tokenAuth(token);
        }
        try {
            const respuesta = await clienteAxios.get('/auth');
            dispatch({
                type: USUARIO_AUTENTICADO,
                payload: respuesta.data.usuario
            })
        } catch (error) {
            // console.log('alv')
            logOut();
        }
    }

    const logOut = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    const cambiarPassword = async (data) => {
        try {
            const respuesta = await clienteAxios.put('/auth', data);
            // console.log(respuesta)
            dispatch({
                type: CERRAR_SESION,
                // payload: respuesta.data
            })
        } catch (error) {
            console.log(error.message)
            const msg = {
                msg: error.response.data.msg,
                categoria: 'btn-log btn-warning mb-1'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: msg
            })
        }
    }

    const msgReset = () => {
        dispatch({
            type: MSG_RESET
        })
    }

    const changeEnfoque = () => {
        dispatch({
            type: CHANGE_ENFOQUE
        })
    }

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                msg: state.msg,
                cargando: state.cargando,
                enfoque: state.enfoque,
                signIn,
                // signUp,
                usuarioAutenticado,
                logOut,
                cambiarPassword,
                changeEnfoque
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;