import React, { useContext, useState } from 'react';
import contactenosContext from '../../context/contactenos/contactenosContext';
import emailjs from '@emailjs/browser';
const Section = () => {

    const { section1 } = useContext(contactenosContext);

    const [data, setData] = useState({
        name: "",
        cell: "",
        email: "",
        city: "",
        questions: ""
    });

    const { name, cell, email, city, questions } = data;

    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const enviarEmail = (e) => {
        e.preventDefault();

        var templateParams = {
            ...data
        };

        // emailjs.send('service_brf144d', 'template_on3wgbn', templateParams, "39CjC2sdSa1YxA6kC")
        emailjs.send('service_7396v3v', 'template_cbqjmdn', templateParams, "pqCYIc_fxbygTyTiA")
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text); setData({
                    name: "",
                    cell: "",
                    email: "",
                    city: "",
                    questions: ""
                });
            }, function (error) {
                console.log('FAILED...', error);
            });
    }

    return (
        <div className='section'>
            <h1>Comunícate con nosotros</h1>
            <div className='section_inner'>
                <div className='mapa'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.4154735404204!2d-75.5195852!3d10.388538399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8ef625f0995959af%3A0x622d0be4ba51fe6f!2sCentro%20Empresarial%20y%20de%20Negocios%20Bosque%20Ejecutivo!5e0!3m2!1ses!2sco!4v1697451258994!5m2!1ses!2sco" width="600" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='datos_contacto'>
                    <p><span className="material-symbols-outlined" style={{ margin: "0 5px" }}>person</span>{section1?.nombre}</p>
                    <p><span className="material-symbols-outlined" style={{ margin: "0 5px" }}>smartphone</span>{section1?.cell}</p>
                    <p><span className="material-symbols-outlined" style={{ margin: "0 5px" }}>mail</span>{section1?.email}</p>
                    <p><span className="material-symbols-outlined" style={{ margin: "0 5px" }}>location_on</span>{section1?.dir}</p>
                    <p><span className="material-symbols-outlined" style={{ margin: "0 5px" }}>schedule</span>{section1?.redes}</p>
                </div>
            </div>
            <section className='inner_contactanos_1'>
                <div className="container container-contactanos">
                    <form id="formulario-contactanos" onSubmit={enviarEmail}>
                        <div className='formulario_items'>
                            <input name="name" type="text" placeholder="Nombre completo" value={name} onChange={onChange}/>
                            <input name="cell" type="text" placeholder="Número de teléfono" value={cell} onChange={onChange} />
                        </div>
                        <div className='formulario_items'>
                            <input name="email" type="text" placeholder="Dirección de correo electrónico" value={email} onChange={onChange} />
                            <input name="city" type="text" placeholder="Ciudad" value={city} onChange={onChange}/>
                        </div>
                        <textarea name="questions" rows="10" cols="50" maxLength={555} style={{ height: "100px" }} placeholder="Comentarios/Preguntas" value={questions} onChange={onChange}/>
                        <button type='submit'>Enviar <i className="fa-solid fa-play fa-2xs"></i></button>
                    </form>
                </div>
            </section>
        </div>
    );
}
export default Section;