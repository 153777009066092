import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Banner from '../components/experiencias/Banner';
import Slider from '../components/experiencias/Slider';
import Section1 from '../components/experiencias/Section1';
import experienciasContext from '../context/experiencias/experienciasContext';

const Experiencias = () => {

    const { getExperiencias } = useContext(experienciasContext);

    // const [selected, setSelected] = useState(null)

    useEffect(() => {
        getExperiencias();
    }, [])

    return (
        <div className='experiencias'>
            <Header/>
            <Banner/>
            <Slider/>
            <Section1/>
            <Footer/>
        </div>
    );
}

export default Experiencias;