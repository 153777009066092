import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';

import InicioContext from './inicioContext';
import InicioReducer from './inicioReducer';

import {
    GET_INFO_INICIO,
    EDITAR_INICIO,
    GET_INFO_ERROR
} from '../../types';
import clienteAxios2 from '../../config/axiosUpload';

const InicioState = props => {

    const initialState = {
        slider: null,
        section1: null,
        banner: null,
    }
    const [state, dispatch] = useReducer(InicioReducer, initialState);

    const getInicio = async () => {
        try {
            const respuesta = await clienteAxios.get(`/inicio`);
            // console.log(respuesta);
            dispatch({
                type: GET_INFO_INICIO,
                payload: respuesta.data.data
            })
        } catch (error) {
            dispatch({
                type: GET_INFO_ERROR,
                payload: error.response.data.msg
            })
        }
    }

    const updateInicio = async (file, newData) => {
        let data = {
            slider: state.slider,
            section1: state.section1,
            banner: state.banner,
        }

        data = {...data, ...newData};
        file.append("data", JSON.stringify(data));
        file.append("ruta", "inicio");

        try {
            const respuesta = await clienteAxios.put(`/inicio`, file);
            const respuesta2 = await clienteAxios2.post(`/upload`, file);
            // console.log(respuesta2);
            dispatch({
                type: EDITAR_INICIO,
                payload: respuesta.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <InicioContext.Provider
            value={{
                slider: state.slider,
                section1: state.section1,
                banner: state.banner,
                getInicio,
                updateInicio
            }}
        >
            {props.children}
        </InicioContext.Provider>
    )
}

export default InicioState;