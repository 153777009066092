import React, { useContext, useEffect, useState } from 'react';
import inicioContext from '../../context/inicio/inicioContext';
import { v4 } from 'uuid';

import urlImages from '../../config/urlImages'

const PanelInicio = () => {
    const { slider, section1, banner, updateInicio } = useContext(inicioContext);

    const [archivo, setArchivo] = useState(null);
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [sliderEdit, setSliderEdit] = useState([]);
    const [sliderDelete, setSliderDelete] = useState('');
    const addSlider = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setSliderEdit([
            ...sliderEdit,
            newName
        ])
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateInicio(f, {
            slider: [
                ...sliderEdit,
                newName
            ]
        });
        // setImagePreview(null);
    }
    const deleteSlider = (e) => {
        e.preventDefault();
        setSliderEdit(sliderEdit.filter((_, indx) => indx !== parseInt(sliderDelete)));
        const f = new FormData();
        updateInicio(f, {
            slider: sliderEdit.filter((_, indx) => indx !== parseInt(sliderDelete))
        });
        setSliderDelete('');
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [section1Edit, setSection1Edit] = useState({});
    const changeInfoSection1 = (e) => {
        e.preventDefault();
        const f = new FormData();
        // console.log(section1Edit.title)
        updateInicio(f, {
            section1: section1Edit
        });
    }
    const changeImagenSection1 = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setSection1Edit({
            ...section1Edit,
            img: newName
        })
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateInicio(f, {
            section1: {
                ...section1Edit,
                img: newName
            }
        });
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    const [bannerEdit, setBannerEdit] = useState({});
    const changeInfoBanner = (e) => {
        e.preventDefault();
        const f = new FormData();
        updateInicio(f, {
            banner: bannerEdit
        });
    }
    const changeImagenBanner = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const newName = v4() + '.' + extension;
        setBannerEdit({
            ...bannerEdit,
            img: newName
        })
        const f = new FormData();
        f.append("archivo", archivo, newName);
        updateInicio(f, {
            banner: {
                ...bannerEdit,
                img: newName
            }
        });
    }
    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        setSliderEdit(slider);
        setSection1Edit(section1);
        setBannerEdit(banner);
    }, [slider, section1, banner]);

    // const [imagePreview, setImagePreview] = useState(null);

    const subirArchivo = (e) => {
        setArchivo(e);

        // if (e) {
        //     // Obtén una URL local para previsualizar la imagen
        //     const imageUrl = URL.createObjectURL(e);
        //     setImagePreview(imageUrl);
        // }
    }

    const insertarArchivos = (e) => {
        e.preventDefault();
        const nombreCortado = archivo.name.split('.');
        const extension = nombreCortado[nombreCortado.length - 1];
        const f = new FormData();
        f.append("archivo", archivo, v4() + '.' + extension);
        // console.log(f.get("archivo"));
        updateInicio(f);
    }

    return (
        <div className='panel_inicio'>
            <div className='panel_rows'>
                <div className='panel_items' style={{ backgroundColor: "var(--secundario_2)" }}>
                    <h2 style={{ color: "white" }}>Inicio</h2>
                </div>
                <div className='panel_items'>
                    <h2>Sección 1</h2>
                    <form id="formulario-contactanos" onSubmit={deleteSlider}>
                        <div className='formulario_items'>
                            <select className="identificacion" name="identificacion" value={sliderDelete} onChange={(e) => setSliderDelete(e.target.value)}>
                                <option value="">Seleccione una imagen</option>
                                {sliderEdit?.map((item, indx) =>
                                    <option value={indx} key={indx}>{indx + 1}</option>
                                )}
                            </select>
                            {sliderDelete != '' ? <img className='img_preview' src={`${urlImages}uploads/inicio/${sliderEdit[sliderDelete]}`} alt="Preview"/> : null}
                            <button style={{ backgroundColor: "var(--secundario_2)" }}>Borrar Imagen Seleccionada<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={addSlider}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            {/* {imagePreview && <img className='img_preview' src={imagePreview} alt="Preview"/>} */}
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 2</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoSection1}>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el Titulo de la sección' value={section1Edit?.title} onChange={(e) => setSection1Edit({ ...section1Edit, title: e.target.value })} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                        <div className='formulario_items'>
                            <textarea name="nombres" type="text" placeholder="Contenido de la sección" maxLength="1000" value={section1Edit?.body} onChange={(e) => setSection1Edit({ ...section1Edit, body: e.target.value })} />
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={changeImagenSection1}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
                <div className='panel_items'>
                    <h2>Sección 3</h2>
                    <form id="formulario-contactanos" onSubmit={changeInfoBanner}>
                        <div className='formulario_items'>
                            <textarea name="titulo" type="text" placeholder='Ingrese el Titulo de la sección' value={bannerEdit?.title} onChange={(e) => setBannerEdit({ ...bannerEdit, title: e.target.value })} />
                            <button>Actualizar informacion<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                    <hr />
                    <form id="formulario-contactanos" onSubmit={changeImagenBanner}>
                        <div className='formulario_items'>
                            <input type="file" name="identificacion" onChange={(e) => subirArchivo(e.target.files[0])} />
                            <button>Subir Imagen<i className="fa-solid fa-play fa-2xs"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default PanelInicio;