import React, { useContext } from 'react';
import queHacemosContext from '../../context/queHacemos/queHacemosContext';
import urlImages from '../../config/urlImages';

const Banner = () => {

    const { banner } = useContext(queHacemosContext);

    return (
        <section className='first_section' id="banner_quienes_somos" style={{backgroundImage: `url("${urlImages}uploads/que_hacemos/${banner?.img}")`}}> 
            <div>
                <h1>{banner?.title}</h1>
                <p>
                    {banner?.body}
                </p>
            </div>
        </section>
    );
}

export default Banner;