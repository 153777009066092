import React, { useEffect, useState } from 'react';
import PanelInicio from './PanelInicio';
import PanelQuienesSomos from './PanelQuienesSomos';
import PanelQueHacemos from './PanelQueHacemos';
import PanelExperiencias from './PanelExperiencias';
import PanelContactenos from './PanelContactenos';
import PanelConfiguracion from './PanelConfiguracion';
const Section = () => {
    const [altura, setAltura] = useState(0);
    const [page, setPage] = useState(0);

    useEffect(() => {
        setAltura(document.getElementById('wraper').offsetHeight);

        function handleResize() {
            setAltura(document.getElementById('wraper').offsetHeight);
        }
        window.addEventListener('resize', handleResize)
    }, [])

    const handleOnClick = (page) => {
        setPage(page);
    }
    
    return ( 
        <div className='section_panel_admin' style={{marginTop: `${altura}px`}}>
            <div className='pages'>
                <ul>
                    <li onClick={() => handleOnClick(0)} className={page === 0 ? 'page_active' : ''}>Inicio</li>
                    <li onClick={() => handleOnClick(1)} className={page === 1 ? 'page_active' : ''}>¿Quiénes Somos?</li>
                    <li onClick={() => handleOnClick(2)} className={page === 2 ? 'page_active' : ''}>¿Qué hacemos?</li>
                    <li onClick={() => handleOnClick(3)} className={page === 3 ? 'page_active' : ''}>Experiencias</li>
                    <li onClick={() => handleOnClick(4)} className={page === 4 ? 'page_active' : ''}>Contáctenos</li>
                    <li onClick={() => handleOnClick(5)} className={page === 5 ? 'page_active' : ''}>configuración</li>
                </ul>
            </div>
            <div className='pages_content'>
                {page === 0 ? <PanelInicio/> : null}
                {page === 1 ? <PanelQuienesSomos/> : null}
                {page === 2 ? <PanelQueHacemos/> : null}
                {page === 3 ? <PanelExperiencias/> : null}
                {page === 4 ? <PanelContactenos/> : null}
                {page === 5 ? <PanelConfiguracion/> : null}
            </div>
        </div>
     );
    }
 
export default Section;