import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';

import QueHacemosContext from './queHacemosContext';
import QueHacemosReducer from './queHacemosReducer';

import {
    GET_INFO_QUE_HACEMOS,
    EDITAR_QUE_HACEMOS,
    GET_INFO_ERROR
} from '../../types';
import clienteAxios2 from '../../config/axiosUpload';

const QueHacemosState = props => {

    const initialState = {
        banner: null,
        section1: null,
        portafolio: null,
        section2: null
    }
    const [state, dispatch] = useReducer(QueHacemosReducer, initialState);

    const getQueHacemos = async () => {
        try {
            const respuesta = await clienteAxios.get(`/quehacemos`);
            // console.log(respuesta.data.data);
            dispatch({
                type: GET_INFO_QUE_HACEMOS,
                payload: respuesta.data.data
            })
        } catch (error) {
            dispatch({
                type: GET_INFO_ERROR,
                payload: error.response.data.msg
            })
        }
    }

    const updateQueHacemos = async (file, newData) => {
        let data = {
            banner: state.banner,
            section1: state.section1,
            portafolio: state.portafolio,
            section2: state.section2
        }

        data = {...data, ...newData};
        // console.log(data);
        file.append("data", JSON.stringify(data));
        file.append("ruta", "que_hacemos");

        try {
            const respuesta = await clienteAxios.put(`/quehacemos`, file);
            const respuesta2 = await clienteAxios2.post(`/upload`, file);

            // console.log(respuesta);
            dispatch({
                type: EDITAR_QUE_HACEMOS,
                payload: respuesta.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <QueHacemosContext.Provider
            value={{
                banner: state.banner,
                section1: state.section1,
                portafolio: state.portafolio,
                section2: state.section2,
                getQueHacemos,
                updateQueHacemos
            }}
        >
            {props.children}
        </QueHacemosContext.Provider>
    )
}

export default QueHacemosState;