import React, { useContext } from 'react';
import quienesSomosContext from '../../context/quienesSomos/quienesSomosContext';
import urlImages from '../../config/urlImages';
const Section1 = () => {

    const { section1 } = useContext(quienesSomosContext);

    return (
        <div className='section'>
            <div className='section_inner'>
                <div className='section_text'>
                    <h2>{section1?.title}</h2>
                    <p>
                        {section1?.body}
                    </p>
                </div>
                <div className='section_img'>
                    <img src={`${urlImages}uploads/quienes_somos/${section1?.img}`}/>
                </div>
            </div>
        </div>
    );
}

export default Section1;