import React, { useContext, useEffect, useState } from 'react';
import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import Section1 from '../components/queHacemos/Section1';
import Section2 from '../components/queHacemos/Section2';
import Banner from '../components/queHacemos/Banner';
import queHacemosContext from '../context/queHacemos/queHacemosContext';
import Portafolio from '../components/queHacemos/Portafolio';

const QueHacemos = () => {

    const { getQueHacemos } = useContext(queHacemosContext);

    const [lineas, setLineas] = useState(0);

    useEffect(() => {
        getQueHacemos();
    }, []);

    return ( 
        <div className='que_hacemos'>
            <Header />
            <Banner/>
            <Section1 setLineas={setLineas}/>
            <Portafolio lineas={lineas}/>
            {/* <Section2/> */}
            <Footer/>
        </div>
     );
}
 
export default QueHacemos;