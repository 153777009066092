import React, { useContext, useState } from 'react';
import experienciasContext from '../../context/experiencias/experienciasContext';
const Section1 = () => {

    const { section1, addComent, updateExperiencias } = useContext(experienciasContext);

    const [comentario, setComentario] = useState("")

    const onChangeComentario = (e) => {
        setComentario(e.target.value)
    }

    const agregarComentario = (e) => {
        e.preventDefault();
        if(comentario.trim() === ""){
            return;
        }
        const createdAt = new Date().toLocaleString() + ""

        const f = new FormData();
        // console.log(section1Edit.title)
        updateExperiencias(f, {
            section1: [...section1, {body: comentario,createdAt: createdAt}]
        });
        // addComent({
        //     body: comentario,
        //     createdAt: createdAt
        // })
        setComentario("");
    }

    return (
        <div className='section'>
            <div className='caja_comentario'>
                <form onSubmit={agregarComentario}>
                    <h2>Tu opinión nos importa, ¡déjanos un comentario!</h2>
                    <textarea id="body" name="body" maxLength="500" value={comentario} onChange={onChangeComentario}/>
                    <button>Enviar</button>
                </form>
            </div>
            <div className='section_title'>
                <h2>Mira las opiniones de los demás</h2>
            </div>
            <div className='comentarios'>
                {section1?.map((item, indx) => 
                    <div className='comentario' key={indx}>
                        <p>{item.body}</p>
                        <p>{item.createdAt}</p>
                    </div>    
                )}
            </div>
        </div>
    );
}

export default Section1;