import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios';

import QuienesSomosContext from './quienesSomosContext';
import QuienesSomosReducer from './quienesSomosReducer';

import {
    GET_INFO_QUIENES_SOMOS,
    EDITAR_QUIENES_SOMOS,
    GET_INFO_ERROR
} from '../../types';
import clienteAxios2 from '../../config/axiosUpload';

const QuienesSomosState = props => {

    const initialState = {
        banner: null,
        section1: null,
        section2: null,
        section3: null
    }
    const [state, dispatch] = useReducer(QuienesSomosReducer, initialState);

    const getQuienesSomos = async () => {
        try {
            const respuesta = await clienteAxios.get(`/quienessomos`);
            // console.log(respuesta);
            dispatch({
                type: GET_INFO_QUIENES_SOMOS,
                payload: respuesta.data.data
            })
        } catch (error) {
            dispatch({
                type: GET_INFO_ERROR,
                payload: error.response.data.msg
            })
        }
    }

    const updateQuienesSomos = async (file, newData) => {
        let data = {
            banner: state.banner,
            section1: state.section1,
            section2: state.section2,
            section3: state.section3
        }

        data = {...data, ...newData};
        // console.log(data);
        file.append("data", JSON.stringify(data));
        file.append("ruta", "quienes_somos");

        try {
            const respuesta = await clienteAxios.put(`/quienessomos`, file);
            const respuesta2 = await clienteAxios2.post(`/upload`, file);

            // console.log(respuesta);
            dispatch({
                type: EDITAR_QUIENES_SOMOS,
                payload: respuesta.data
            })
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <QuienesSomosContext.Provider
            value={{
                banner: state.banner,
                section1: state.section1,
                section2: state.section2,
                section3: state.section3,
                getQuienesSomos,
                updateQuienesSomos
            }}
        >
            {props.children}
        </QuienesSomosContext.Provider>
    )
}

export default QuienesSomosState;