import { useEffect, useState } from 'react';
import SpinnerGif from '../../img/1.png';
const Spinner = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const tiempoCarga = 500; // Tiempo de carga en milisegundos
        const incremento = 10; // Incremento del progress bar en milisegundos

        const intervalo = setInterval(() => {
            setProgress((prevProgress) => {
                const porcentaje = prevProgress + (incremento / tiempoCarga) * 100;
                return porcentaje >= 100 ? 100 : porcentaje; // Asegurar que no supere el 100%
            });
        }, incremento);

        return () => {
            clearInterval(intervalo);
        };
    }, []);

    return (
        <div className="lazy-wrapper">
            <div className="bg">
                <div className="container-spinner">
                    <img src={SpinnerGif} width={100} />
                    <div className="progress_bar">
                        <div className="progressed_bar progress" style={{ width: `${Math.round(progress)}%` }}></div>
                    </div>
                    <p>Cargando el sistema... {Math.round(progress)}%</p>
                </div>
            </div>
        </div>
    );
}

export default Spinner;