import { useContext, useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import authContext from "../context/authentication/authContext";

const PrivateRoute = () => {
  const {autenticado, usuarioAutenticado} = useContext(authContext);
  useEffect(() => {
    usuarioAutenticado();
  }, [])

  return autenticado ? <Outlet/> : <Navigate to={'/signin'} />
};

export default PrivateRoute;
