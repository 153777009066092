import React, { useContext, useEffect } from 'react';
import Slider from '../components/inicio/Slider';
import Header from '../components/layouts/Header';
import Section1 from '../components/inicio/Section1';
import Banner from '../components/inicio/Banner';
import Footer from '../components/layouts/Footer';
import Donaciones from '../components/layouts/Donaciones';
import inicioContext from '../context/inicio/inicioContext';

const Inicio = () => {
    const {getInicio} = useContext(inicioContext);

    useEffect(() => {
        getInicio();
    }, []);
    
    return (
        <div className='inicio' id='inicio'>
            <Header/>
            <Slider/>
            <Section1/>
            <Banner/>
            <Donaciones/>
            <Footer/>
        </div>
    );
}

export default Inicio;