import React, { useContext, useEffect, useRef, useState } from 'react';
import inicioContext from '../../context/inicio/inicioContext';
import urlImages from '../../config/urlImages';

const Slider = () => {

    const { slider } = useContext(inicioContext);

    const [info, setInfo] = useState({
        width: 0,
        maxWidth: 0,
        translate: 0,
        move: 0
    });

    const { width, maxWidth, translate, move } = info;

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        if (slider?.length > 1) {
            setInfo({
                ...info,
                maxWidth: parseInt(document.getElementById('items_container').offsetWidth * slider?.length),
                width: parseInt(document.getElementById('items_container').offsetWidth),
                translate: 0,
                move: 1
            });
        } else {
            setInfo({
                ...info,
                maxWidth: parseInt(document.getElementById('items_container').offsetWidth * slider?.length),
                width: parseInt(document.getElementById('items_container').offsetWidth),
                translate: 0,
                move: 0
            });
        }
        document.getElementById('items_container').style.transform = 'translateX(0px)';
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })

        }

        window.addEventListener('resize', handleResize)

        // console.log(dimensions)
        return _ => {
            window.removeEventListener('resize', handleResize)

        }
    }, [slider, dimensions]);

    const prev = () => {
        if (!move) {
            return;
        }
        const items_container = document.getElementById('items_container');
        if (translate - width >= 0) {//verificamos que el tamaño de el desplazamiento sea menor a el contenedor, para evitar que se siga desplazando y deje espacios vacios
            setInfo({
                ...info, translate: translate - width
            });
            items_container.style.transform = 'translateX(-' + parseInt(translate - width) + 'px)';//se usa la propiedad transform: translate para el movimiento
        } else {
            setInfo({
                ...info, translate: maxWidth - width
            });
            items_container.style.transform = 'translateX(-' + parseInt(maxWidth - width) + 'px)';//se usa la propiedad transform: translate para el movimiento
        }
    }

    const next = () => {
        if (!move) {
            return;
        }
        const items_container = document.getElementById('items_container');
        // console.log(info);
        if (translate + width < (maxWidth)) {
            setInfo({
                ...info, translate: translate + width
            });
            items_container.style.transform = 'translateX(-' + parseInt(translate + width) + 'px)';
        } else {
            setInfo({
                ...info, translate: 0
            });
            items_container.style.transform = 'translateX(-' + 0 + 'px)';
        }
    }

    return (
        <div className='slider' id='slider'>
            <>
                <div className="slider-arrow slider-arrow-left" onClick={prev}>&#10094;</div>
                <div className="slider-arrow slider-arrow-right" onClick={next}>&#10095;</div>
            </>
            <div className='slider_inner' id='slider_inner'>
                <div className='items_container' id='items_container' style={{ transform: `translate(-${translate}px)` }}>
                    {slider ?//si existe info en slider
                        (
                            slider?.length > 0 ? //si hay imagenes en el proyecto seleccionado
                                (
                                    slider.map((item, indx) =>
                                        <div className='item' id='item' key={indx}>
                                            {/* <div className='item' id='item' key={indx} ref={elRefs[indx]} onMouseOut={() => onChangeScroll(indx)}> */}
                                            <img src={`${urlImages}uploads/inicio/${item}`} />
                                        </div>)
                                )
                                :
                                (
                                    <div className='item' id='item'>
                                        {/* <h2>Ups!, aun no hay imágenes en este proyecto!</h2> */}
                                        <img src={`${urlImages}uploads/default.jpg`} />
                                    </div>
                                )
                        )
                        :
                        (<div className='item' id='item'>
                            <img src={`${urlImages}uploads/default.jpg`} />
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}

export default Slider;